
import Vue from "vue";
import FileUploader from "@/components/form-components/FileUploader.vue";
import {
  emailRule,
  exactRule,
  maxLengthRule,
  minLengthRule,
  phoneRule,
  requiredRule
} from "@/services/helpers/validation-rules";
import store from "@/store";
import { mask } from "vue-the-mask";

export default Vue.extend({
  name: "ProfilePersonal",

  components: { FileUploader },

  directives: {
    mask
  },

  props: {
    value: {
      required: false,
      type: Boolean,
      default: false
    },
    user: {
      type: Object,
      required: true
    },
    isOwner: {
      type: Boolean,
      required: false,
      default: false
    }
  },

  data: () => ({
    document: { title: "", document: null } as any,
    dialog: false as boolean,
    loading: false as boolean,
    errorMessages: {} as any,
    activePickerData: "",
    newGender: "m",
    selects: {
      states: [] as Array<any>,
      genders: [
        {
          value: "m",
          text: "М"
        },
        {
          value: "f",
          text: "Ж"
        }
      ]
    },
    model: {} as any,
    menu: false as boolean,
    credentials: store.getters["authentication/credentials"],
    isFile: false as boolean,
    //todo сделать через тайп и убрать все что с этима связано
    filesIds: [] as any
  }),

  watch: {
    menu(val) {
      val &&
        setTimeout(() => {
          this.activePickerData = "YEAR";
        });
    }
  },

  computed: {
    rules() {
      return {
        exactRule: exactRule.bind(this),
        emailRule: emailRule.bind(this),
        requiredRule: requiredRule.bind(this),
        minLengthRule: minLengthRule.bind(this),
        maxLengthRule: maxLengthRule.bind(this),
        phoneRule: phoneRule.bind(this)
      };
    },
    maxUploadDocuments() {
      return this.user.documents.length < 100;
    },
    separateUserName() {
      return this.user.name.split("‎ ");
    },
    // Format the SSN numbers by replacing the first chars with an asterix
    formattedSSN() {
      if (this.user.ssn) {
        return this.$can('change','SSN') ? this.user.ssn : '*'.repeat(5) + this.user.ssn.slice(-4);
      }
      return "";
    }
  },

  async mounted() {
    await this.loadStates();
  },

  methods: {
    async loadStates() {
      try {
        this.selects.states = await this.$API.location().getStates();
      } catch (e) {
        await this.$store.dispatch("alert/showError", e.message);
      }
    },
    save(date: string) {
      (this.$refs.picker as any).activePicker = this.activePickerData;
      (this.$refs.menu as any).save(date);
    },
    async changePersonalData() {
      try {
        this.user.gender = "m";
        if(await (this.$refs.form as any).validate()) {
          this.user.name =
              this.separateUserName[0] + "‎ " + this.separateUserName[1];
          await this.$API.user().changePersonalData(this.user.id, this.user);
          await this.$store.dispatch(
              "alert/showSuccess",
              this.$i18n.t('global_messages.personal_change_success')
          );
        }
      } catch (e) {
        if (e.hasOwnProperty("errors")) {
          this.errorMessages = e.errors;
        }
        await this.$store.dispatch("alerts/showError", e.message);
      }
    },
    async addDocument() {
      this.loading = true;
      try {
        const response = await this.$API
          .user()
          .uploadUserDocuments(this.document, this.user.id);

        this.user.documents.push(response);
        this.dialog = false;
        this.document = { title: "", document: null };
        await this.$store.dispatch(
          "alert/showSuccess",
          "Document added successfully"
        );
      } catch (e) {
        if (e.hasOwnProperty("errors")) {
          this.errorMessages = e.errors;
        }
        await this.$store.dispatch("alerts/showError", e.message);
      }
      this.loading = false;
    },
    //todo splice
    async deleteDocument(document: any): Promise<void> {
      try {
        await this.$API.user().deleteDocument(document.id);
        this.user.documents = this.user.documents.filter(
          (item: any) => item.id !== document.id
        );

        await this.$store.dispatch(
          "alert/showSuccess",
          "Document deleted successfully!"
        );
      } catch (e) {
        if (e.hasOwnProperty("errors")) {
          this.errorMessages = e.errors;
        }
        await this.$store.dispatch("alerts/showError", e.message);
      }
    },
    getSize(size: number): string {
      let formattedSize = "";
      if (size < 1000000) {
        formattedSize = `${Math.floor(size / 10) / 100} KB`;
      } else {
        formattedSize = `${Math.floor(size / 10000) / 100} MB`;
      }
      return formattedSize;
    },
    toggleFile(item: number) {
      this.filesIds.push(item);
    },
    isDocumentDeleteButtonAccessible(document: any) {
      if (document.name === "Engagement_and_Limited_Agency_Agreement.pdf") {
        return false;
      }

      return this.$can("delete", "Documents") || !document.is_permanent;
    },
    async redirectToDashboard(){
      await this.$router.push('/profile/'+this.user.id+'/dashboard');
    }
  }
});
