import { LocaleMessages } from "vue-i18n";

export const exactRule = function(
  number: number,
  selectValidation = false
): Function {
  if (!selectValidation) {
    return (v: string): boolean | string | LocaleMessages =>
      !v ||
      v.length === number ||
      // @ts-ignore
      this.$t("global_validation.exact", { number });
  }
  return (v: string | any): boolean | string | LocaleMessages =>
    !v ||
    v.text.length === number ||
    // @ts-ignore
    this.$t("global_validation.exact", { number });
};

export const minLengthRule = function(number: number): Function {
  return (v: string): boolean | string | LocaleMessages =>
    // @ts-ignore
    !v || v.length >= number || this.$t("global_validation.min", { number });
};

export const maxLengthRule = function(
  number: number,
  selectValidation = false
): Function {
  if (!selectValidation) {
    return (v: string): boolean | string | LocaleMessages =>
      // @ts-ignore
      !v || v.length <= number || this.$t("global_validation.max", { number });
  }
  return (v: any): boolean | any | LocaleMessages =>
    !v ||
    !v.text ||
    v.text.length <= number ||
    // @ts-ignore
    this.$t("global_validation.max", { number });
};

export const requiredRule = function(
  field: string,
  disableValidation: any = false
): Function {
  return (v: string): boolean | string | LocaleMessages =>
    (!disableValidation && !!v) ||
    disableValidation ||
    // @ts-ignore
    this.$t("global_validation.required_filed", { field });
};

export const minNumberRule = function(number: number): Function {
  return (v: string): boolean | string | LocaleMessages =>
    !v ||
    Number(v) >= number ||
    // @ts-ignore
    this.$t("global_validation.min_number", { number });
};

export const maxNumberRule = function(number: number): Function {
  return (v: string): boolean | string | LocaleMessages =>
    !v ||
    Number(v) <= number ||
    // @ts-ignore
    this.$t("global_validation.max_number", { number });
};

export const emailRule = function(
  v: any,
  selectValidation = false
): boolean | string | LocaleMessages {
  // const emailValidation = /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/;
  const emailValidation = /^[^@]+@\w+(\.\w+)+\w$/;

  if (!selectValidation) {
    return (
      !v ||
      emailValidation.test(v) ||
      // @ts-ignore
      this.$t("global_validation.email")
    );
  }
  return (
    (!!v && emailValidation.test(v.text)) ||
    // @ts-ignore
    this.$t("global_validation.email")
  );
};

export const minCountRule = function(number: number): Function {
  return (v: string): boolean | string | LocaleMessages =>
    !v ||
    v.length >= number ||
    // @ts-ignore
    this.$t("global_validation.min_count", { number });
};

export const websiteRule = function(): Function {
  return (v: string): boolean | string | LocaleMessages =>
    !v ||
    /.\../.test(v) ||
    // @ts-ignore
    this.$t("global_validation.email");
};

export const phoneRule = function(selectValidation = false): Function {
  if (!selectValidation) {
    return (v: string): boolean | string | LocaleMessages =>
      !v ||
      /^[0-9,]*$/.test(v) ||
      // @ts-ignore
      this.$t("global_validation.phone");
  }
  return (v: any): boolean | string | LocaleMessages =>
    !v ||
    /^[0-9,]*$/.test(v.text) ||
    // @ts-ignore
    this.$t("global_validation.phone");
};

export const serviceCodeRule = function(): Function {
  const reg1 = /[0-9]{4}[.][0-9]{2,3}(?:(.)([0-9]{1,3}))/;
  const reg2 = /[0-9]{4}[.][0-9]{2,3}/;

  return (v: string): boolean | string | LocaleMessages =>
    !v || v.split(".").length == 2
      ? reg2.test(v)
      : reg1.test(v) ||
        // @ts-ignore
        this.$t("global_validation.code");
};

export const spaceCheckRule = function(value: any): string | boolean {
  if (value.trim() === "") {
    return "Field should not contain only spaces.";
  }
  return true; // Return true if the validation passes
};

export const letterCountRule = function(word: any): string | boolean {
  const letterCount = word.replace(/\s/g, "").length;
  if (letterCount < 3) {
    return "Field should contain at least 3 letters (excluding spaces).";
  }
  return true;
};
